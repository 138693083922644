import {ActionList, Box, PageLayout, Text} from '@primer/react'

import type {Facet} from '../../types/blackbird-types'

import type {SearchTypeURLParameter} from '../../../../../components/search/parsing/parsing'
import EducationGroup from './EducationGroup'
import TypeGroup from './TypeGroup'
import FiltersGroup from './FilterGroups'

import type {SearchKindCount} from '../../hooks/use-search-result-counts'
interface FacetListProps extends MobileFacetListProps {
  selectedType: string | undefined
  searchKinds: SearchKindCount[]
  isLoadingCounters: boolean
}

function FacetList({
  facets,
  allowNegation,
  selectedType,
  searchKinds,
  searchType,
  resultCount,
  isLoading,
  isLoadingCounters,
}: FacetListProps) {
  return (
    <Box
      sx={{
        fontSize: 1,
        fontWeight: 'normal',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div data-testid="desktop-filters">
        <ActionList aria-labelledby="search-filters-title" data-testid={'filter-groups'}>
          <TypeGroup
            isLoadingCounters={isLoadingCounters}
            isMobile={false}
            selectedType={selectedType}
            searchKinds={searchKinds}
          />
          <FiltersGroup
            facets={facets}
            searchType={searchType}
            allowNegation={allowNegation}
            isLoading={isLoading}
            resultCount={resultCount}
          />
          <ActionList.Divider sx={{mx: 2, my: 2}} />
          <EducationGroup />
        </ActionList>
      </div>
    </Box>
  )
}

export function FacetsPane({
  selectedType,
  searchKinds,
  isLoading,
  isLoadingCounters,
  facets,
  searchType,
  resultCount,
}: {
  facets: Facet[] | null
  searchType: SearchTypeURLParameter | null
  selectedType: string | undefined
  searchKinds: SearchKindCount[]
  isLoading: boolean
  isLoadingCounters: boolean
  resultCount: number | null
}) {
  return (
    <PageLayout.Pane
      aria-labelledby="search-filters-title"
      position="start"
      divider="line"
      width="medium"
      resizable
      widthStorageKey="search-sidebar-width"
      sticky={true}
      hidden={{narrow: true, regular: false, wide: false}}
    >
      <Text as="h2" id="search-filters-title" sx={{fontWeight: 'semibold', px: 3, pt: 3, pb: 2, fontSize: 2}}>
        Filter by
      </Text>
      <Box data-testid="facets-pane" sx={{pt: 1, px: 2, display: 'flex', flexDirection: 'column'}}>
        <Box sx={{flex: 1}}>
          <FacetList
            facets={facets}
            resultCount={resultCount}
            allowNegation={searchType === 'code'}
            selectedType={selectedType}
            searchType={searchType}
            searchKinds={searchKinds}
            isLoading={isLoading}
            isLoadingCounters={isLoadingCounters}
          />
        </Box>
      </Box>
    </PageLayout.Pane>
  )
}

interface MobileFacetListProps {
  facets: Facet[] | null
  allowNegation: boolean
  searchType: SearchTypeURLParameter | null
  resultCount: number | null
  isLoading: boolean
}

function MobileFacetList({facets, allowNegation, searchType, resultCount, isLoading}: FacetListProps) {
  return (
    <Box
      sx={{
        fontSize: 1,
        fontWeight: 'normal',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div data-testid="mobile-filters">
        <ActionList aria-labelledby="search-filters-title" data-testid={'mobile-filter-groups'}>
          <FiltersGroup
            facets={facets}
            searchType={searchType}
            allowNegation={allowNegation}
            isLoading={isLoading}
            resultCount={resultCount}
            mobile
          />
        </ActionList>
      </div>
    </Box>
  )
}

export function FacetsDialogContent({
  selectedType,
  searchKinds,
  isLoading,
  isLoadingCounters,
  facets,
  searchType,
  resultCount,
}: {
  facets: Facet[] | null
  searchType: SearchTypeURLParameter | null
  selectedType: string | undefined
  searchKinds: SearchKindCount[]
  isLoading: boolean
  isLoadingCounters: boolean
  resultCount: number | null
}) {
  return (
    <Box data-testid="facets-pane" sx={{display: 'flex', flexDirection: 'column'}}>
      <Box sx={{flex: 1}}>
        <MobileFacetList
          facets={facets}
          resultCount={resultCount}
          allowNegation={searchType === 'code'}
          selectedType={selectedType}
          searchType={searchType}
          searchKinds={searchKinds}
          isLoading={isLoading}
          isLoadingCounters={isLoadingCounters}
        />
      </Box>
    </Box>
  )
}

try{ FacetList.displayName ||= 'FacetList' } catch {}
try{ FacetsPane.displayName ||= 'FacetsPane' } catch {}
try{ MobileFacetList.displayName ||= 'MobileFacetList' } catch {}
try{ FacetsDialogContent.displayName ||= 'FacetsDialogContent' } catch {}