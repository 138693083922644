import {GitPullRequestIcon, IssueOpenedIcon, CheckCircleIcon, type Icon} from '@primer/octicons-react'

function issueIsOpen(state: string) {
  return state === 'open'
}

export function getIssueIconColor(state: string, isPullRequest: boolean, merged?: boolean, reviewableState?: string) {
  if (isPullRequest) {
    if (reviewableState === 'draft') {
      return 'fg.muted'
    } else if (merged) {
      return 'done.fg'
    } else if (issueIsOpen(state)) {
      return 'open.fg'
    } else {
      return 'closed.fg'
    }
  } else if (issueIsOpen(state)) {
    return 'success.fg'
  } else {
    return 'done.fg'
  }
}

export function getIssueIcon(state: string, isPullRequest: boolean): Icon {
  return isPullRequest ? GitPullRequestIcon : issueIsOpen(state) ? IssueOpenedIcon : CheckCircleIcon
}
