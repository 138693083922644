import React, {useEffect} from 'react'
import {FilterIcon, BookmarkIcon} from '@primer/octicons-react'
import {Box, Button, Octicon, Text, Token} from '@primer/react'
import ExtraMenu from './ExtraMenu'

import {Dialog} from '@primer/react/experimental'
import {
  CodeSortItems,
  CommitSortItems,
  DiscussionSortItems,
  FocusHintPrefix,
  IssueSortItems,
  PackageSortItems,
  RepoSortItems,
  type SearchResults,
  UserSortItems,
  WikiSortItems,
} from '../types/blackbird-types'
import {CountMode, getCountText} from '../../react-shared/Count'
import {useLocation} from 'react-router-dom'

import {FacetsDialogContent} from './FacetsPane'
import SortMenu from './SortMenu'
import TypeMobileDropdown from './FacetsPane/TypeMobileDropdown'
import {parseString} from '../../../../components/search/parsing/parsing'
import type {ContentNode, NodeWithChildren, QualifierNode} from '@github/blackbird-parser'
import {useNavigateToQuery} from '../hooks/use-navigate-to-query'
import type {SearchKindCount} from '../hooks/use-search-result-counts'
import {ExperimentsDialog} from './ExperimentsDialog'
import {useFocusHintContext} from '@github-ui/focus-hint-context'
import {useLoggedInContext} from '../contexts/LoggedInContext'
import {debugScoringInfoEnabled} from '../../../../components/search/experiments'

export function SearchSubHeader({
  payload,
  selectedType,
  searchKinds,
  isLoadingCounters,
  isLoading,
}: {
  payload: SearchResults
  selectedType: string | undefined
  searchKinds: SearchKindCount[]
  isLoadingCounters: boolean
  isLoading: boolean
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [openExperimentsDialog, setOpenExperimentsDialog] = React.useState(false)
  const openDialog = React.useCallback(() => setIsOpen(true), [setIsOpen])
  const closeDialog = React.useCallback(() => setIsOpen(false), [setIsOpen])
  const loggedIn = useLoggedInContext()
  const navigateToQuery = useNavigateToQuery()

  const sortItems = getSortItemsForType(payload.type)
  const isCode = payload.type === 'code'
  const isLegacyCode = payload.type === 'codelegacy'
  const isAnyCode = isCode || isLegacyCode

  const openCustomScopesDialog = (event: React.SyntheticEvent) => {
    const e = new CustomEvent('blackbird_monolith_save_query_as_custom_scope', {detail: event.currentTarget})
    window.dispatchEvent(e)
  }

  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const query = urlSearchParams.get('q') || ''
  const searchKindCount = searchKinds?.find(kind => kind.name === selectedType) || searchKinds[0]!
  const showDebugScoringInfo = debugScoringInfoEnabled()

  const {focusHint} = useFocusHintContext()
  useEffect(() => {
    // focus on the search result count on page load
    if (!isLoading) {
      const focusElement = document.getElementById('search-results-count')
      if (!focusHint?.startsWith(FocusHintPrefix)) {
        focusElement?.focus()
      }
    }
  })

  return (
    <div data-testid="search-sub-header">
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        {loggedIn || !isCode ? (
          <ResultCount
            isLoadingCounters={isLoadingCounters}
            elapsedMilliseconds={payload.elapsed_millis}
            searchKinds={searchKinds}
            searchKindCount={searchKindCount}
            query={query}
          />
        ) : (
          <Box sx={{flex: 2}}>
            <Box sx={{display: ['block', 'block', 'none']}}>
              <TypeMobileDropdown
                selectedType={searchKindCount.name}
                searchKinds={searchKinds}
                isLoadingCounters={isLoadingCounters}
              />
            </Box>
          </Box>
        )}
        {isCode && showDebugScoringInfo && (
          <div>
            {`cost=${payload.metadata?.total_cost} retries=${payload.metadata?.retries} limit_reached=${payload.metadata?.limit_reached}`}
          </div>
        )}
        <Box
          sx={{
            display: 'flex',
            flexShrink: 0,
          }}
        >
          {sortItems && (
            <Box sx={{display: ['none', 'none', 'block'], mr: 2}}>
              <SortMenu onlyGroup={false} sortItems={sortItems} />
            </Box>
          )}
          {loggedIn && (
            <Box sx={{display: ['none', 'none', 'block'], mr: 2}}>
              <Button onClick={openCustomScopesDialog} size="small">
                <Box sx={{flexDirection: 'row', alignItems: 'center'}}>
                  <Octicon icon={BookmarkIcon} sx={{mr: 1}} />
                  Save
                </Box>
              </Button>
            </Box>
          )}

          <Box sx={{display: (searchKindCount.total || 0) === 0 ? 'none' : ['block', 'block', 'none'], mr: 2}}>
            <Button onClick={openDialog} size="small" data-testid="filter-button" leadingVisual={FilterIcon}>
              Filter
            </Button>
            {isOpen && (
              <Dialog
                title="Filters"
                renderBody={() =>
                  renderFilterDialogBody(payload, isLoading, isLoadingCounters, selectedType, searchKinds)
                }
                onClose={closeDialog}
                position={{narrow: 'bottom', wide: 'center'}}
              />
            )}
          </Box>
          <Box sx={{display: ['block', 'block', 'none']}}>
            <ExtraMenu
              sortItems={sortItems}
              isCode={isAnyCode}
              onClickSave={openCustomScopesDialog}
              openExperimentsDialog={() => setOpenExperimentsDialog(true)}
              refreshSearch={() => navigateToQuery(query, undefined)}
              isMobile={true}
            />
          </Box>
          {openExperimentsDialog && (
            <ExperimentsDialog
              onDismiss={() => setOpenExperimentsDialog(false)}
              onSave={() => navigateToQuery(query, undefined)}
            />
          )}

          <Box sx={{display: ['none', 'none', 'block']}}>
            <ExtraMenu
              isCode={isAnyCode}
              sortItems={sortItems}
              onClickSave={openCustomScopesDialog}
              openExperimentsDialog={() => setOpenExperimentsDialog(true)}
              refreshSearch={() => navigateToQuery(query, undefined)}
              isMobile={false}
            />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

function ResultCount({
  elapsedMilliseconds,
  searchKinds,
  searchKindCount,
  isLoadingCounters,
  query,
}: {
  elapsedMilliseconds: number
  searchKinds: SearchKindCount[]
  searchKindCount: SearchKindCount
  isLoadingCounters: boolean
  query: string
}) {
  const {focusHint} = useFocusHintContext()
  const secondsForResults = elapsedMilliseconds >= 1000
  const isCode = searchKindCount.name === 'code'
  const unit = isCode ? 'file' : 'result'
  const resultsUnit = searchKindCount.total === 1 ? ` ${unit} ` : ` ${unit}s `
  const timeMeasurement = secondsForResults ? Math.round(elapsedMilliseconds / 1000) : elapsedMilliseconds
  const timeUnit = secondsForResults ? 's' : 'ms'
  const countText =
    (searchKindCount.mode === CountMode.Approximate ? 'About ' : '') +
    (searchKindCount.mode === CountMode.LowerBound ? 'More than ' : '') +
    getCountText(searchKindCount.total || 0) +
    resultsUnit

  return (
    <>
      <Text
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          flex: 2,
          rowGap: 1,
          fontSize: 2,
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{display: ['block', 'block', 'none']}}>
          <TypeMobileDropdown
            selectedType={searchKindCount.name}
            searchKinds={searchKinds}
            isLoadingCounters={isLoadingCounters}
          />
        </Box>

        <Text
          sx={{
            fontWeight: 'semibold',
            pr: 1,
            display: ['none', 'none', 'flex'],
            justifyContent: 'center',
            flexShrink: 0,
            alignItems: 'baseline',
          }}
        >
          <Box sx={{display: 'inline'}}>{countText}</Box>
          <Text sx={{fontSize: 0, fontWeight: 'normal', color: 'fg.muted'}}>
            &nbsp;({timeMeasurement} {timeUnit})
          </Text>
        </Text>
        <h2
          id="search-results-count"
          className="sr-only"
          data-react-autofocus={focusHint?.startsWith(FocusHintPrefix) ? null : true}
          tabIndex={-1}
        >
          {countText}
        </h2>
        <FilterToken query={query} />
      </Text>
    </>
  )
}

function FilterToken({query}: {query: string}) {
  const navigateToQuery = useNavigateToQuery()
  const astNode: NodeWithChildren = parseString(query || '') as NodeWithChildren

  // No qualifiers found
  if (!astNode.children || astNode.children.length === 0) {
    return null
  }

  // Check if query is a simple query
  const simpleQueryQualifiers = ['And', 'Or', 'Not', 'Group']
  const isSimpleQuery = astNode.children.filter(node => simpleQueryQualifiers.includes(node.kind)).length === 0
  if (!isSimpleQuery) {
    return null
  }

  const qualifierNodes = astNode.children.filter(
    node =>
      node.kind === 'Qualifier' && (node.qualifier === 'Repo' || node.qualifier === 'Org' || node.qualifier === 'User'),
  ) as QualifierNode[]

  // No repo, org, or user qualifiers found or more than 1
  // Also - in case the qualifier is actuall blank ie. `repo: ` with a space, return null
  if (!qualifierNodes || qualifierNodes.length !== 1 || qualifierNodes[0]!.content.kind === 'Nothing') {
    return null
  }

  const qualifierNode = qualifierNodes[0]!
  // Start at the beginning of the qualifier node, end at the end of content
  const qualifierStartLocation = qualifierNode.location.start
  const qualifierEndLocation = (qualifierNode.content as ContentNode).location.end

  return (
    <Text
      sx={{
        fontSize: 0,
        fontWeight: 'normal',
        color: 'fg.muted',
        display: 'inline-flex',
        alignItems: 'center',
        maxWidth: '100%',
      }}
    >
      in
      <Token
        as="a"
        size="medium"
        href={`/${(qualifierNode.content as ContentNode).value.toString()}`}
        text={(qualifierNode.content as ContentNode).value.toString()}
        onRemove={() => {
          query = (query.substring(0, qualifierStartLocation) + query.substring(qualifierEndLocation)).trim()
          navigateToQuery(query, undefined)
        }}
        sx={{
          ml: 1,
        }}
      />
    </Text>
  )
}

function renderFilterDialogBody(
  payload: SearchResults,
  isLoading: boolean,
  isLoadingCounters: boolean,
  selectedType: string | undefined,
  searchKinds: SearchKindCount[],
) {
  return (
    <Box sx={{overflow: 'scroll'}}>
      <FacetsDialogContent
        facets={payload.facets}
        searchType={payload.type}
        isLoading={isLoading}
        isLoadingCounters={isLoadingCounters}
        searchKinds={searchKinds}
        selectedType={selectedType}
        resultCount={payload.result_count}
      />
    </Box>
  )
}

function getSortItemsForType(type: string) {
  switch (type) {
    case 'codelegacy':
      return CodeSortItems
    case 'commits':
      return CommitSortItems
    case 'discussions':
      return DiscussionSortItems
    case 'registrypackages':
      return PackageSortItems
    case 'repositories':
      return RepoSortItems
    case 'users':
      return UserSortItems
    case 'wikis':
      return WikiSortItems
    case 'issues':
      return IssueSortItems
    case 'pullrequests':
      return IssueSortItems
    default:
      return undefined
  }
}

try{ SearchSubHeader.displayName ||= 'SearchSubHeader' } catch {}
try{ ResultCount.displayName ||= 'ResultCount' } catch {}
try{ FilterToken.displayName ||= 'FilterToken' } catch {}