import {Box, Text, ActionList} from '@primer/react'
import type {Topic} from '../../types/blackbird-types'
import {HashIcon, LinkIcon, RepoIcon} from '@primer/octicons-react'
import {topicPath} from '@github-ui/paths'
import {GitHubAvatar} from '@github-ui/github-avatar'

function FeaturedTopic({topic, linkedTopic}: {topic: Topic; linkedTopic: string}) {
  const {name, display_name, short_description, github_url, logo_url, development_logo_url, url, wikipedia_url} = topic
  // eslint-disable-next-line no-restricted-syntax
  const nwo = github_url ? new URL(github_url).pathname.substring(1) : null
  // eslint-disable-next-line no-restricted-syntax
  const urlHost = url ? new URL(url).hostname.replace('www.', '') : null
  const topicUrl = topicPath({topicName: linkedTopic})
  const logo = development_logo_url || logo_url
  return (
    <>
      <Box
        sx={{
          bg: 'canvas.default',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'border.default',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'column',
          pt: 3,
          pb: 1,
        }}
      >
        <Box sx={{display: 'flex', alignItems: 'center', mb: 2, mx: 3}}>
          {logo && <GitHubAvatar alt={linkedTopic} src={logo} size={24} square sx={{mr: 2}} />}
          <Text sx={{color: 'fg.default', fontWeight: 'semibold', fontSize: 2}}>{display_name ?? name}</Text>
        </Box>
        <Box sx={{mx: 3}}>
          <Text sx={{color: 'fg.muted'}}>{short_description}</Text>
        </Box>

        <ActionList>
          {url && (
            <ActionList.LinkItem
              href={url}
              sx={{
                span: {
                  color: 'accent.fg',
                },
              }}
            >
              <ActionList.LeadingVisual>
                <LinkIcon />
              </ActionList.LeadingVisual>
              {urlHost}
            </ActionList.LinkItem>
          )}

          {wikipedia_url && (
            <ActionList.LinkItem
              href={wikipedia_url}
              sx={{
                span: {
                  color: 'accent.fg',
                },
              }}
            >
              <ActionList.LeadingVisual>
                <LinkIcon />
              </ActionList.LeadingVisual>
              Wikipedia
            </ActionList.LinkItem>
          )}

          {github_url && (
            <ActionList.LinkItem href={github_url} sx={{color: 'fg.muted'}}>
              <ActionList.LeadingVisual>
                <RepoIcon />
              </ActionList.LeadingVisual>
              {nwo}
            </ActionList.LinkItem>
          )}
          <ActionList.LinkItem href={topicUrl} sx={{color: 'fg.muted'}}>
            <ActionList.LeadingVisual>
              <HashIcon />
            </ActionList.LeadingVisual>
            View topic
          </ActionList.LinkItem>
        </ActionList>
      </Box>
    </>
  )
}

export default FeaturedTopic

try{ FeaturedTopic.displayName ||= 'FeaturedTopic' } catch {}