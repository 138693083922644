import {getUrl, searchPath} from '@github-ui/paths'
import {useLocation, type Location} from 'react-router-dom'
import {updateSearchBar} from '../hooks/use-search-bar-events'
import {useCallback} from 'react'
import {useSoftNavigate} from './use-soft-navigate'
import type {CustomScope} from '../types/blackbird-types'
import {getExpandedQuery, parseString} from '../../../../../app/components/search/parsing/parsing'
import {getBlackbirdExperiments} from '../../../../components/search/experiments'

export function shouldOpenInNewTab(event: React.MouseEvent | React.KeyboardEvent) {
  // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
  return event.ctrlKey || event.metaKey || (event as React.MouseEvent).button === 1
}

/**
 * Returns a function that will update the search results and the search header with the new query.
 */
export function useNavigateToQuery(): (
  query?: string,
  filter?: string,
  searchParams?: {[key: string]: string | null},
  openInNewTab?: boolean,
) => void {
  const navigateToSearch = useSoftNavigate(searchPath)
  const location = useLocation()
  return useCallback(
    async (query?: string, filter?: string, searchParams?: {[key: string]: string | null}, openInNewTab?: boolean) => {
      const {newQuery, newSearchParams} = getNewQueryUrlInfo(location, query, filter, searchParams)

      updateSearchBar(newQuery)
      if (openInNewTab) {
        window.open(getUrl(searchPath, undefined, {...newSearchParams, q: newQuery}).href, '_blank')
      } else {
        navigateToSearch(undefined, {...newSearchParams, q: newQuery})
      }
    },
    [navigateToSearch, location],
  )
}

export function useQueryUrl(): (
  query?: string,
  filter?: string,
  searchParams?: {[key: string]: string | null},
) => string {
  const location = useLocation()

  return useCallback(
    (query?: string, filter?: string, searchParams?: {[key: string]: string | null}) => {
      const {newQuery, newSearchParams} = getNewQueryUrlInfo(location, query, filter, searchParams)

      return getUrl(searchPath, undefined, {...newSearchParams, q: newQuery}).href
    },
    [location],
  )
}

export function getCustomScopesFromParam(scopesParam: string): CustomScope[] {
  let customScopes: CustomScope[]
  try {
    customScopes = JSON.parse(scopesParam || '[]')
    if (!Array.isArray(customScopes)) {
      customScopes = []
    }
  } catch (e) {
    customScopes = []
  }
  return customScopes
}

function getNewQueryUrlInfo(
  location: Location,
  query?: string,
  filter?: string,
  searchParams?: {[key: string]: string | null},
) {
  const currentSearchParams = new URLSearchParams(location.search)
  let newQuery = query ?? currentSearchParams.get('q') ?? ''
  if (filter) {
    newQuery = newQuery ? `${newQuery} ${filter}` : filter
  }

  const newSearchParams = searchParams || {}

  const experimentsParam = getBlackbirdExperiments().join(',')
  if (experimentsParam !== '') {
    newSearchParams['experiments'] = experimentsParam
  } else {
    // Must forcibly unset, in case the user has previously set experiments on the last pageload
    newSearchParams['experiments'] = null
  }

  const scopesParam = currentSearchParams.get('saved_searches')
  if (scopesParam) {
    const customScopes = getCustomScopesFromParam(scopesParam)
    if (customScopes.length > 0) {
      const ast = parseString(newQuery)
      newSearchParams['expanded_query'] = getExpandedQuery(newQuery, customScopes, ast)
    }
  }

  return {
    newQuery,
    newSearchParams,
  }
}
