import {Ago} from '@github-ui/ago'
import {Link, Octicon, Text} from '@primer/react'
import type {DiscussionResult as DiscussionResultType, DiscussionsResults} from '../../types/blackbird-types'
import {CommentDiscussionIcon} from '@primer/octicons-react'
import {Count} from '../../../react-shared/Count'
import Result from '../search-result'
import {GitHubAvatar} from '@github-ui/github-avatar'

function Discussions({results}: {results: DiscussionsResults}) {
  return (
    <Result.List>
      {results.results.map((item, i) => (
        <DiscussionResult item={item} key={i} />
      ))}
    </Result.List>
  )
}

function DiscussionResult({item}: {item: DiscussionResultType}) {
  const repositoryName = item.repo.repository.name
  const repositoryOwnerName = item.repo.repository.owner_login
  const createdDate = new Date(item.created)

  return (
    <Result>
      <Result.Header>
        <Result.RepositoryContext name={repositoryName} owner={repositoryOwnerName} />
        <span className="sr-only">:&nbsp;</span>
        <Result.Title>
          <Link href={item.url}>{item.title}</Link>
        </Result.Title>
      </Result.Header>

      {item.hl_text && (
        <Result.Content>
          <span>
            <Result.SearchMatchText text={item.hl_text} />
          </span>
        </Result.Content>
      )}

      <Result.Footer>
        <Result.FooterItem>
          <GitHubAvatar
            size={16}
            src={item.development_avatar || item.user_avatar_url}
            sx={{
              mr: 2,
            }}
          />
          <Link href={`/${item.user_login}`}>
            <Text sx={{color: 'fg.default', fontWeight: 'semibold', pr: 1}}>{item.user_login}</Text>
          </Link>
          <Text sx={{pr: 1}}>posted</Text>
          <Ago timestamp={createdDate} />
        </Result.FooterItem>

        <Result.FooterItem>
          <Octicon
            icon={CommentDiscussionIcon}
            size={16}
            sx={{
              mr: 1,
              color: 'fg.muted',
            }}
          />
          <Count ariaLabel={`${item.num_comments} comments`} value={item.num_comments} />
        </Result.FooterItem>
      </Result.Footer>
    </Result>
  )
}

export default Discussions

try{ Discussions.displayName ||= 'Discussions' } catch {}
try{ DiscussionResult.displayName ||= 'DiscussionResult' } catch {}