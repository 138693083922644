import {Ago} from '@github-ui/ago'
import {Link, Octicon, Label, Box} from '@primer/react'
import type {PackageResultItem, PackagesResults} from '../../types/blackbird-types'
import {Count} from '../../../react-shared/Count'
import {DownloadIcon} from '@primer/octicons-react'
import Result from '../search-result'
import {packageIconFromName} from '../PackageIcons'
import {formatPackageTitle} from '../../utilities/format-type-title'

function Packages({results}: {results: PackagesResults}) {
  return <Result.List>{results.results?.map((item, i) => <PackageResult key={i} item={item} />)}</Result.List>
}

function PackageResult({item}: {item: PackageResultItem}) {
  const updatedAt = new Date(item.updated_at)

  const repositoryName = item.repo.name
  const repositoryOwnerName = item.repo.owner_login
  const latestVersions = item.source.versions.filter(version => version.latest)
  const latestVersion = [...latestVersions].pop()?.version

  return (
    <Result>
      <Result.Header>
        {repositoryOwnerName && repositoryName && (
          <Result.RepositoryContext owner={repositoryOwnerName} name={repositoryName} />
        )}
        <span className="sr-only">:&nbsp;</span>
        <Result.Title>
          <Link href={item.package_url}>
            <span>{item.name}</span>
          </Link>
          {!item.public && <Label sx={{ml: 1}}>{item.source.visibility}</Label>}
        </Result.Title>
      </Result.Header>

      {item.summary && (
        <Result.Content>
          <span>{item.summary}</span>
        </Result.Content>
      )}

      <Result.Footer>
        <Result.FooterItem>
          {item.color && <Box sx={{height: 16, mr: 2}}>{packageIconFromName(item.source.package_type)}</Box>}
          {/* eslint-disable-next-line github/a11y-role-supports-aria-props */}
          <span aria-label={`${item.source.package_type} type`}>{formatPackageTitle(item.source.package_type)}</span>
        </Result.FooterItem>
        {latestVersion && (
          <Result.FooterItem>
            <span>{latestVersion}</span>
          </Result.FooterItem>
        )}
        <Result.FooterItem>
          <Octicon
            icon={DownloadIcon}
            size={16}
            sx={{
              mx: '6px',
              color: 'fg.subtle',
            }}
          />
          <Count ariaLabel={`${item.downloads} downloads`} value={item.downloads} />
        </Result.FooterItem>
        <Result.FooterItem>
          <span>
            Updated <Ago timestamp={updatedAt} />
          </span>
        </Result.FooterItem>
      </Result.Footer>
    </Result>
  )
}

export default Packages

try{ Packages.displayName ||= 'Packages' } catch {}
try{ PackageResult.displayName ||= 'PackageResult' } catch {}