import type React from 'react'
import {useMemo} from 'react'
import {ActionMenu, IconButton, ActionList} from '@primer/react'
import type {SortItem} from '../types/blackbird-types'
import {debugScoringInfoEnabled, setDebugScoringInfoEnabled} from '../../../../components/search/experiments'

import {provideFeedback} from '../hooks/use-search-bar-events'
import {
  BookIcon,
  BeakerIcon,
  CommentIcon,
  BookmarkIcon,
  LinkExternalIcon,
  KebabHorizontalIcon,
} from '@primer/octicons-react'

import SortMenu from './SortMenu'

// NOTE: this import is verified to work under SSR
import {isStaff} from '@github-ui/stats'
import {useLoggedInContext} from '../contexts/LoggedInContext'

type ExtraMenuType = {
  isCode: boolean
  onClickSave: (event: React.SyntheticEvent) => void
  sortItems?: SortItem[]
  isMobile: boolean
  openExperimentsDialog: () => void
  refreshSearch: () => void
}

export default function ExtraMenu({
  isCode,
  onClickSave,
  sortItems,
  isMobile,
  openExperimentsDialog,
  refreshSearch,
}: ExtraMenuType) {
  const userIsStaff = useMemo(() => {
    return isStaff()
  }, [])

  const loggedIn = useLoggedInContext()

  const showDebugScoringInfo = debugScoringInfoEnabled()

  return (
    <ActionMenu>
      <ActionMenu.Anchor>
        {/* eslint-disable-next-line primer-react/a11y-remove-disable-tooltip */}
        <IconButton
          unsafeDisableTooltip={true}
          icon={KebabHorizontalIcon}
          size="small"
          aria-label="Open column options"
          sx={{color: 'fg.muted'}}
        />
      </ActionMenu.Anchor>

      <ActionMenu.Overlay align="end" width={isCode ? 'medium' : 'small'}>
        <ActionList>
          {sortItems && isMobile && (
            <>
              <SortMenu onlyGroup={true} sortItems={sortItems} />
              {isMobile && <ActionList.Divider />}
            </>
          )}
          <ActionList.Group>
            {isCode || (sortItems && isMobile) ? <ActionList.GroupHeading>More options</ActionList.GroupHeading> : null}
            {isMobile && (
              <ActionList.Item onSelect={onClickSave}>
                <ActionList.LeadingVisual>
                  <BookmarkIcon />
                </ActionList.LeadingVisual>
                Save {isMobile && 'your search'}
              </ActionList.Item>
            )}
            <ActionList.LinkItem href="https://docs.github.com/search-github">
              <ActionList.LeadingVisual>
                <BookIcon />
              </ActionList.LeadingVisual>
              View search docs
            </ActionList.LinkItem>
            {loggedIn && (
              <ActionList.LinkItem
                href="#"
                onClick={(e: React.MouseEvent) => {
                  provideFeedback()
                  e.preventDefault()
                  // NOTE: This is necessary to prevent the dialog from instantly closing
                  e.stopPropagation()
                }}
              >
                <ActionList.LeadingVisual>
                  <CommentIcon />
                </ActionList.LeadingVisual>
                Give feedback
              </ActionList.LinkItem>
            )}
            <ActionList.LinkItem href="https://github.com/search/advanced">
              <ActionList.LeadingVisual>
                <LinkExternalIcon />
              </ActionList.LeadingVisual>
              Advanced search
            </ActionList.LinkItem>

            {userIsStaff && (
              <>
                <ActionList.Divider />
                <ActionList.Group>
                  <ActionList.GroupHeading>Staff</ActionList.GroupHeading>
                  <ActionList.Item onSelect={() => openExperimentsDialog()}>
                    <ActionList.LeadingVisual>
                      <BeakerIcon />
                    </ActionList.LeadingVisual>
                    Experiments
                  </ActionList.Item>
                  <ActionList.Item
                    onSelect={() => {
                      setDebugScoringInfoEnabled(!showDebugScoringInfo)
                      refreshSearch()
                    }}
                  >
                    <ActionList.LeadingVisual>
                      <BeakerIcon />
                    </ActionList.LeadingVisual>
                    {showDebugScoringInfo ? 'Hide' : 'Show'} debug scoring info
                  </ActionList.Item>
                </ActionList.Group>
              </>
            )}
          </ActionList.Group>
        </ActionList>
      </ActionMenu.Overlay>
    </ActionMenu>
  )
}

try{ ExtraMenu.displayName ||= 'ExtraMenu' } catch {}