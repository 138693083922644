import React, {type RefObject} from 'react'
import {ActionList, Octicon} from '@primer/react'
import {IssueOpenedIcon, CheckCircleIcon, PlusCircleIcon} from '@primer/octicons-react'

import {appendAndFocusSearchBar} from '@github-ui/append-and-focus-search-bar'
import {shouldOpenInNewTab, useNavigateToQuery, useQueryUrl} from '../../hooks/use-navigate-to-query'
import {getFilters} from './utils/get-filters'
import FacetOption, {HEIGHT_ACTION_LIST_ITEM} from './FacetOption'
import EllipsisOverflow from '../EllipsisOverflow'
import type {SearchTypeURLParameter} from '../../../../../components/search/parsing/parsing'
import FacetsSkeletonGroups from '../skeletons/FacetsSkeletonGroups'
import {
  type Facet,
  type FacetEntry,
  FacetKindLanguage,
  FacetKindPath,
  FacetKindRepo,
  FacetKindPackageType,
  FocusHintPrefix,
} from '../../types/blackbird-types'
import {useFocusHintContext} from '@github-ui/focus-hint-context'

const FacetFocusHint = `${FocusHintPrefix}facet`

interface RenderableFacet {
  title: string
  items: FacetEntry[]
  onClick?: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item: FacetEntry) => void
  footerItem?: {
    text: string
    ref: RefObject<HTMLButtonElement>
    onClick: () => void
  }
  kind: string
}

function FilterGroups({
  facets,
  allowNegation,
  searchType,
  isLoading,
  resultCount,
  mobile,
}: {
  searchType: SearchTypeURLParameter | null
  facets: Facet[] | null
  allowNegation: boolean
  isLoading: boolean
  resultCount: number | null
  mobile?: boolean
}) {
  const footerRef = React.useRef<HTMLButtonElement>(null)
  const navigateToQuery = useNavigateToQuery()
  const getQueryUrl = useQueryUrl()
  const {focusHint, context: focusContext, setFocusHint} = useFocusHintContext()

  if (isLoading) {
    return <FacetsSkeletonGroups />
  }

  const extraLeadingFacets = (searchType && getExtraLeadingFacets(searchType, navigateToQuery)) || []
  const filters = (searchType && getFilters(searchType)) || []
  const resultCountSafe = resultCount || 0

  if (!isLoading && resultCountSafe === 0) {
    return null
  }

  const newFacets = extraLeadingFacets

  if (facets) {
    for (const facet of facets) {
      if (facet.entries.length === 0) continue

      let footerItem = undefined

      if (facet.kind === FacetKindLanguage) {
        footerItem = {
          text: 'More languages...',
          ref: footerRef,
          onClick: () => {
            appendAndFocusSearchBar({
              appendQuery: 'language:',
              returnTarget: footerRef.current ?? undefined,
            })
          },
        }
      } else if (facet.kind === FacetKindRepo) {
        footerItem = {
          text: 'More repositories...',
          ref: footerRef,
          onClick: () => {
            appendAndFocusSearchBar({appendQuery: 'repo:', returnTarget: footerRef.current ?? undefined})
          },
        }
      } else if (facet.kind === FacetKindPath) {
        footerItem = {
          text: 'More directories...',
          ref: footerRef,
          onClick: () => {
            appendAndFocusSearchBar({appendQuery: 'path:', returnTarget: footerRef.current ?? undefined})
          },
        }
      }

      newFacets.push({
        items: facet.entries.map(entry => ({...entry, shouldNavigate: true})),
        onClick: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item: FacetEntry) => {
          if (!item || event.defaultPrevented) return
          const name = facet.kind === FacetKindLanguage ? item.name : null
          navigateToQuery(undefined, item.query, {l: name, ...item.searchParams}, shouldOpenInNewTab(event))
          event.preventDefault()
        },
        footerItem,
        kind: facet.kind,
        title:
          {
            [FacetKindLanguage]: 'Languages',
            [FacetKindRepo]: 'Repositories',
            [FacetKindPath]: 'Paths',
            [FacetKindPackageType]: 'Types',
          }[facet.kind] || '',
      })
    }
  }

  if (filters && filters.length) {
    newFacets.push({
      title: 'Advanced',
      items: filters,
      onClick: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item: FacetEntry) => {
        if (!item || event.defaultPrevented) return
        if (item.shouldNavigate) {
          navigateToQuery(undefined, ` ${item.query}`, {p: null}, shouldOpenInNewTab(event))
        } else {
          appendAndFocusSearchBar({appendQuery: item.query})
        }
        event.preventDefault()
      },
      kind: 'filter',
    } as RenderableFacet)
  }

  return (
    <>
      {newFacets.length > 1 && <ActionList.Divider sx={{mx: 2, my: 2, display: ['none', 'none', 'block']}} />}
      {newFacets.map((facet, index, all) => (
        <ActionList.Group key={`${facet.title}-${index}`}>
          <ActionList.GroupHeading as="h3">{facet.title}</ActionList.GroupHeading>
          {facet.items.map((item, sugIndex) => (
            <FacetOption
              item={item}
              key={`${sugIndex}`}
              autofocus={focusHint === FacetFocusHint && focusContext === index && sugIndex === 0}
              onSelect={e => {
                setFocusHint(FacetFocusHint, index)
                facet.onClick?.(e, item)
                e.preventDefault()
                e.stopPropagation()
              }}
              href={getQueryUrl(undefined, item.query, {
                ...item.searchParams,
                l: null,
                p: null,
              })}
              kind={facet.kind}
              allowNegation={allowNegation}
              mobile={mobile}
            />
          ))}

          {facet.footerItem && (
            <ActionList.LinkItem
              as="button"
              tabIndex={0}
              onClick={(e: React.MouseEvent) => {
                facet.footerItem?.onClick()

                // NOTE: it's necessary to stop propagation in order to avoid having the click
                // steal focus away from the search input, causing it to close right after opening!
                e.preventDefault()
                e.stopPropagation()
              }}
              sx={{
                alignItems: 'center',
                height: HEIGHT_ACTION_LIST_ITEM,
                minWidth: 0,
                textAlign: 'left',

                '&:is(button)': {
                  display: 'flex',
                },
              }}
              ref={facet.footerItem.ref}
            >
              <ActionList.LeadingVisual sx={{mx: 2, color: 'fg.muted'}}>
                <PlusCircleIcon />
              </ActionList.LeadingVisual>
              <EllipsisOverflow title={facet.footerItem.text} side="right" sx={{maxWidth: '100%'}}>
                {facet.footerItem.text}
              </EllipsisOverflow>
            </ActionList.LinkItem>
          )}

          {index !== all.length - 1 && (
            <ActionList.Divider key={`${facet.title}-${index}-divider`} sx={{mx: 2, my: 2}} />
          )}
        </ActionList.Group>
      ))}
    </>
  )
}

function getExtraLeadingFacets(
  searchType: SearchTypeURLParameter,
  navigateToQuery: (
    query?: string,
    filter?: string,
    searchParams?: {[key: string]: string | null},
    openInNewTab?: boolean,
  ) => void,
): RenderableFacet[] {
  switch (searchType) {
    case 'issues':
    case 'pullrequests':
      return [
        {
          title: 'State',
          items: [
            {
              name: 'Open',
              query: '',
              shouldNavigate: true,
              visual: <Octicon icon={IssueOpenedIcon} sx={{color: 'success.fg'}} size={16} />,
              searchParams: {state: 'open'},
            },
            {
              name: 'Closed',
              query: '',
              shouldNavigate: true,
              visual: <Octicon icon={CheckCircleIcon} sx={{color: 'done.fg'}} size={16} />,
              searchParams: {state: 'closed'},
            },
          ],
          kind: 'state',
          onClick: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item: FacetEntry) => {
            navigateToQuery(undefined, ` ${item.query}`, {p: null, ...item.searchParams}, shouldOpenInNewTab(event))
          },
        },
      ]
    default:
      return []
  }
}

// not a huge perf hit but for some reason innocent re-renders cause this to lose focus
export default React.memo(FilterGroups)

try{ FilterGroups.displayName ||= 'FilterGroups' } catch {}