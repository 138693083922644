import {Box, Button, Link, Text, Octicon, themeGet} from '@primer/react'
import type {SearchResponse, UserResult as UserResultType, UsersResults} from '../../types/blackbird-types'
import React from 'react'
import {useVerifiedFetch} from '@github-ui/use-verified-fetch'
import Result from '../search-result'
import {SafeHTMLBox, type SafeHTMLString} from '@github-ui/safe-html'
import {Count} from '../../../react-shared/Count'
import {PeopleIcon, RepoIcon} from '@primer/octicons-react'
import {GitHubAvatar} from '@github-ui/github-avatar'

function Users({results}: {results: UsersResults & SearchResponse}) {
  const [sponsorButtonMap, setSponsorButtonMap] = React.useState<{[key: string]: SafeHTMLString}>()

  const getSponsors = useVerifiedFetch<{[key: string]: SafeHTMLString}>(
    '/sponsors/batch_deferred_sponsor_buttons',
    'post',
  )

  React.useEffect(() => {
    async function populateSponsorButtonMap(formData: FormData) {
      const sponsorResponse = await getSponsors({body: formData})

      if (sponsorResponse.ok) {
        const sponsorButtons = await sponsorResponse.json()

        setSponsorButtonMap(sponsorButtons)
      }
    }

    const sponsorFormData = new FormData()

    for (const result of results.results) {
      const formRepoKey = `items[item-${result.id}]`

      if (result.sponsorable && !sponsorFormData.has(`${formRepoKey}[sponsorable_id]`)) {
        if (!sponsorFormData.has('_method')) {
          sponsorFormData.set('_method', 'GET')
        }

        sponsorFormData.set(`${formRepoKey}[sponsorable_id]`, result.id)
        sponsorFormData.set(`${formRepoKey}[sponsorable_login]`, result.login)
        sponsorFormData.set(`${formRepoKey}[repo_name]`, '')
        sponsorFormData.set(`${formRepoKey}[has_funding_file]`, 'false')
        sponsorFormData.set(`${formRepoKey}[location]`, 'USER_SEARCH_RESULT_SPONSOR')
      }
    }

    if (sponsorFormData.has('_method')) {
      populateSponsorButtonMap(sponsorFormData)
    }
  }, [results, getSponsors])

  return (
    <Result.List>
      {results.results.map((item, i) => {
        let sponsorButtonHtml: SafeHTMLString | undefined
        if (sponsorButtonMap) {
          sponsorButtonHtml = sponsorButtonMap[`item-${item.id}`]
        }

        return (
          <UserResult
            item={item}
            key={i}
            sponsorButtonHtml={sponsorButtonHtml}
            signInPath={!results.logged_in ? results.sign_in_path : ''}
          />
        )
      })}
    </Result.List>
  )
}

function UserResult({
  item,
  sponsorButtonHtml,
  signInPath,
}: {
  item: UserResultType
  sponsorButtonHtml?: SafeHTMLString
  signInPath?: string
}) {
  return (
    <Result>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <Box sx={{display: 'flex', flexDirection: 'row', maxWidth: themeGet('breakpoints.0')}}>
          <Box sx={{flexShrink: 0}}>
            <GitHubAvatar src={item.development_avatar || item.avatar_url} size={40} sx={{mr: 3, mt: 1}} />
          </Box>
          <Box
            sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, borderTopColor: 'border.default', minWidth: 0}}
          >
            <Result.Header>
              <Result.Title>
                <Title user={item} />
              </Result.Title>
            </Result.Header>

            {item.hl_profile_bio && (
              <Result.Content>
                <span>
                  <Result.SearchMatchText text={item.hl_profile_bio} />
                </span>
              </Result.Content>
            )}

            {item.location && (
              <Result.Footer>
                <Result.FooterItem>
                  <span>{item.location}</span>
                </Result.FooterItem>

                <Result.FooterItem>
                  <Octicon
                    icon={RepoIcon}
                    size={16}
                    sx={{
                      color: 'fg.subtle',
                      mr: '6px',
                    }}
                  />
                  <Count ariaLabel={`${item.repos} repositories`} value={item.repos} />
                </Result.FooterItem>

                <Result.FooterItem>
                  <Octicon
                    icon={PeopleIcon}
                    size={16}
                    sx={{
                      color: 'fg.subtle',
                      mr: '6px',
                    }}
                  />
                  <Count ariaLabel={`${item.followers} followers`} value={item.followers} />
                </Result.FooterItem>
              </Result.Footer>
            )}
          </Box>
        </Box>
        <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-end', flexBasis: 'auto'}}>
          {!item.is_current_user && (
            <div>
              <FollowButton followedByUser={item.followed_by_current_user} login={item.login} signInPath={signInPath} />
            </div>
          )}
          {sponsorButtonHtml && <SafeHTMLBox html={sponsorButtonHtml} sx={{ml: 2}} />}
        </Box>
      </Box>
    </Result>
  )
}

function Title({user}: {user: UserResultType}) {
  const userHref = `/${user.display_login}`
  return (
    <>
      {user.hl_name && (
        <Link href={userHref}>
          <Text sx={{fontSize: 2, fontWeight: 'semibold'}}>{user.name}</Text>
        </Link>
      )}
      <Link href={userHref}>
        <Text sx={{color: 'fg.muted', ml: user.name ? 1 : undefined, fontWeight: 'normal'}}>
          <Text sx={{fontSize: 2, fontWeight: 'light'}}>{user.display_login}</Text>
        </Text>
      </Link>
    </>
  )
}

function FollowButton({
  followedByUser,
  login,
  signInPath,
}: {
  followedByUser: boolean
  login: string
  signInPath?: string
}) {
  const [followed, setFollowed] = React.useState(followedByUser)
  const action = useVerifiedFetch<void>(`/users/${followed ? 'unfollow' : 'follow'}?target=${login}`, 'post')

  const onToggle = React.useCallback(async () => {
    setFollowed(!followed)
    const res = await action()
    if (!res.ok) {
      // following failed, so revert back to the previous state
      setFollowed(followed)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followed])

  return signInPath ? (
    <Button size="small" as="a" href={signInPath}>
      {followed ? 'Unfollow' : 'Follow'}
    </Button>
  ) : (
    <Button onClick={onToggle} size="small">
      {followed ? 'Unfollow' : 'Follow'}
    </Button>
  )
}

export default Users

try{ Users.displayName ||= 'Users' } catch {}
try{ UserResult.displayName ||= 'UserResult' } catch {}
try{ Title.displayName ||= 'Title' } catch {}
try{ FollowButton.displayName ||= 'FollowButton' } catch {}