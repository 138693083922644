import {BellIcon, SearchIcon, XCircleFillIcon, LogoGithubIcon, MarkGithubIcon} from '@primer/octicons-react'
import {Box, Octicon, Text, TextInput, Link} from '@primer/react'
import useColorModes from '@github-ui/react-core/use-color-modes'
import React, {type FormEvent, type KeyboardEventHandler, useState, useEffect, useMemo} from 'react'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {showPreferences} from '@github-ui/cookie-consent'
import PrefixesDialog from '../components/PrefixesDialog'
import {useNavigateToQuery} from '../hooks/use-navigate-to-query'
import type {HomePayload} from '../types/blackbird-types'

export default function Home() {
  const [value, setValue] = useState('')
  const payload = useRoutePayload<HomePayload | null>()
  const returnFocusRef = React.useRef(null)
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const navigateToQuery = useNavigateToQuery()

  useHideHeader()

  const handleKeyPress: KeyboardEventHandler = e => {
    // TODO: somehow exempt react apps from this?
    // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic -- manual shortcut logic is idiomatic in React
    if (value && e.key === 'Enter') {
      navigateToQuery(value)
    }
  }

  const colorMode = useColorModes()

  const lightIllustrationPath = '/images/modules/search/home-desktop-light.png'
  const lightIllustrationPath2x = '/images/modules/search/home-desktop-light2x.png'
  const lightIllustrationPathWebP = '/images/modules/search/home-desktop-light.webp'
  const lightIllustrationPathWebP2x = '/images/modules/search/home-desktop-light2x.webp'

  const darkIllustrationPath = '/images/modules/search/home-desktop-dark.png'
  const darkIllustrationPath2x = '/images/modules/search/home-desktop-dark2x.png'
  const darkIllustrationPathWebP = '/images/modules/search/home-desktop-dark.webp'
  const darkIllustrationPathWebP2x = '/images/modules/search/home-desktop-dark2x.webp'

  const lightIllustrationPathMobile = '/images/modules/search/home-mobile-light.png'
  const lightIllustrationPathMobile2x = '/images/modules/search/home-mobile-light2x.png'
  const lightIllustrationPathMobileWebP = '/images/modules/search/home-mobile-light.webp'
  const lightIllustrationPathMobileWebP2x = '/images/modules/search/home-mobile-light2x.webp'

  const darkIllustrationPathMobile = '/images/modules/search/home-mobile-dark.png'
  const darkIllustrationPathMobile2x = '/images/modules/search/home-mobile-dark2x.png'
  const darkIllustrationPathMobileWebP = '/images/modules/search/home-mobile-dark.webp'
  const darkIllustrationPathMobileWebP2x = '/images/modules/search/home-mobile-dark2x.webp'

  const colorModeAwareMona = useMemo(() => {
    switch (colorMode.colorMode) {
      case 'night':
        return darkIllustrationPath
      case 'day':
      default:
        return lightIllustrationPath
    }
  }, [colorMode])

  const colorModeAwareMonaMobile = useMemo(() => {
    switch (colorMode.colorMode) {
      case 'night':
        return darkIllustrationPathMobile
      case 'day':
      default:
        return lightIllustrationPathMobile
    }
  }, [colorMode])

  const colorModeAwareMonaWebP = useMemo(() => {
    switch (colorMode.colorMode) {
      case 'night':
        return darkIllustrationPathWebP
      case 'day':
      default:
        return lightIllustrationPathWebP
    }
  }, [colorMode])

  const colorModeAwareMonaMobileWebP = useMemo(() => {
    switch (colorMode.colorMode) {
      case 'night':
        return darkIllustrationPathMobileWebP
      case 'day':
      default:
        return lightIllustrationPathMobileWebP
    }
  }, [colorMode])

  const colorModeAwareMona2x = useMemo(() => {
    switch (colorMode.colorMode) {
      case 'night':
        return darkIllustrationPath2x
      case 'day':
      default:
        return lightIllustrationPath2x
    }
  }, [colorMode])

  const colorModeAwareMonaMobile2x = useMemo(() => {
    switch (colorMode.colorMode) {
      case 'night':
        return darkIllustrationPathMobile2x
      case 'day':
      default:
        return lightIllustrationPathMobile2x
    }
  }, [colorMode])

  const colorModeAwareMonaWebP2x = useMemo(() => {
    switch (colorMode.colorMode) {
      case 'night':
        return darkIllustrationPathWebP2x
      case 'day':
      default:
        return lightIllustrationPathWebP2x
    }
  }, [colorMode])

  const colorModeAwareMonaWebMobileP2x = useMemo(() => {
    switch (colorMode.colorMode) {
      case 'night':
        return darkIllustrationPathMobileWebP2x
      case 'day':
      default:
        return lightIllustrationPathMobileWebP2x
    }
  }, [colorMode])

  return (
    <Box
      data-hpc
      sx={{
        minHeight: '100vh',
        minWidth: 320,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          px: 3,
          py: 10,
        }}
      >
        <Box sx={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <Box sx={{mb: [3, 3, 4]}}>
            <Box sx={{display: ['none', 'none', 'block']}}>
              <LogoGithubIcon size={50} />
            </Box>
            <Box sx={{display: ['block', 'block', 'none']}}>
              <LogoGithubIcon size={30} />
            </Box>
          </Box>
          <Box sx={{px: 2, width: '100%', maxWidth: 600, textAlign: 'center'}}>
            <TextInput
              aria-label="Search GitHub"
              sx={{
                width: '100%',
                height: 42,
              }}
              onKeyPress={handleKeyPress}
              autoFocus
              leadingVisual={() => (
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Octicon
                    icon={SearchIcon}
                    sx={{
                      width: [16, 20],
                      height: [16, 20],
                      color: 'fg.muted',
                    }}
                  />
                </Box>
              )}
              trailingVisual={() => (
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  {value && (
                    <Box
                      onClick={() => setValue('')}
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Octicon
                        icon={XCircleFillIcon}
                        sx={{
                          color: 'fg.muted',
                          cursor: 'pointer',
                          width: [16, 16],
                          height: [16, 16],

                          '&:hover': {
                            color: 'fg.default',
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
              placeholder="Search GitHub"
              value={value}
              onChange={(e: FormEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
            />
          </Box>

          <Box sx={{textAlign: 'center', mt: [3, 3, 4]}}>
            <Box sx={{mr: [1, 2], display: 'inline-block'}}>
              <Octicon
                icon={BellIcon}
                sx={{
                  color: 'attention.fg',
                  mr: 1,
                  width: [14, 16],
                  height: [14, 16],
                }}
              />
              <Text sx={{color: 'attention.fg', fontSize: [0, 1]}}>Tip</Text>
            </Box>
            <Text sx={{color: 'fg.muted', fontSize: [0, 1]}}>
              For an{' '}
              <Link inline href="/search/advanced">
                advanced search
              </Link>
              , use our{' '}
              <Link
                as="button"
                inline
                ref={returnFocusRef}
                onClick={() => {
                  setDialogIsOpen(true)
                }}
              >
                prefixes
              </Link>
            </Text>
            <PrefixesDialog
              isOpen={dialogIsOpen}
              returnFocusRef={returnFocusRef}
              rootHelpUrl={payload?.helpUrl as string}
              onDismiss={() => setDialogIsOpen(false)}
            />
          </Box>
          <Box sx={{mt: [6, 8, 8], display: 'flex', justifyContent: 'center'}}>
            <Box sx={{display: ['block', 'block', 'block', 'none', 'none'], maxWidth: [351]}}>
              <picture>
                <source
                  srcSet={`${colorModeAwareMonaMobileWebP} 1x, ${colorModeAwareMonaWebMobileP2x} 2x`}
                  type="image/webp"
                />
                <img
                  src={colorModeAwareMonaMobile}
                  srcSet={`${colorModeAwareMonaMobile} 1x, ${colorModeAwareMonaMobile2x} 2x`}
                  alt="Illustration of Mona looking through binoculars at the a building a supernatural landscape"
                  width="100%"
                />
              </picture>
            </Box>
            <Box sx={{display: ['none', 'none', 'none', 'block', 'block'], maxWidth: [500, 600, 700, 800, 800]}}>
              <picture>
                <source srcSet={`${colorModeAwareMonaWebP} 1x, ${colorModeAwareMonaWebP2x} 2x`} type="image/webp" />
                <img
                  src={colorModeAwareMona}
                  srcSet={`${colorModeAwareMona} 1x, ${colorModeAwareMona2x} 2x`}
                  alt="Illustration of Mona looking through binoculars at the a building a supernatural landscape"
                  width="100%"
                />
              </picture>
            </Box>
          </Box>
        </Box>
      </Box>

      <Footer helpUrl={payload?.helpUrl as string} cookieConsentEnabled={payload?.cookie_consent_enabled} />
    </Box>
  )
}

function Footer({helpUrl, cookieConsentEnabled}: {helpUrl: string; cookieConsentEnabled: boolean | undefined}) {
  const currentYear = new Date().getFullYear()

  return (
    <>
      <Box
        as="footer"
        sx={{
          display: 'flex',
          justifyContent: ['center'],
          alignItems: 'center',
          flexWrap: 'wrap',
          flexDirection: ['row'],
          width: '100%',
          px: [5, 5, 5],
          py: 5,
          fontSize: 0,
          color: 'fg.muted',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: ['row'],
            marginX: '2',
          }}
        >
          <Link aria-label="GitHub" href="/" muted sx={{marginRight: '2'}}>
            <Octicon
              icon={MarkGithubIcon}
              sx={{
                width: 24,
                height: 24,
                color: 'fg.muted',
              }}
            />
          </Link>

          <span>GitHub, Inc. © {currentYear}</span>
        </Box>

        <Link href="https://docs.github.com/github/site-policy/github-terms-of-service" muted sx={{marginX: '2'}}>
          Terms
        </Link>

        <Link
          href="https://docs.github.com/site-policy/privacy-policies/github-privacy-statement"
          muted
          sx={{marginX: '2'}}
        >
          Privacy
        </Link>

        <Link href="/security" muted sx={{marginX: '2'}}>
          Security
        </Link>

        <Link href="https://www.githubstatus.com" muted sx={{marginX: '2'}}>
          Status
        </Link>

        <Link href={helpUrl} muted sx={{marginX: '2'}}>
          Docs
        </Link>

        <Link href="https://support.github.com/?tags=dotcom-footer" muted sx={{marginX: '2'}}>
          Contact
        </Link>

        {cookieConsentEnabled ? (
          <>
            <Link as="button" onClick={() => showPreferences()} muted sx={{marginX: '2'}}>
              Manage Cookies
            </Link>

            <Link as="button" onClick={() => showPreferences()} muted sx={{marginX: '2'}}>
              Do not share my personal information
            </Link>
          </>
        ) : null}
      </Box>
    </>
  )
}

function useHideHeader() {
  useEffect(() => {
    const headerDiv: HTMLElement | null =
      document.querySelector('.AppHeader') || document.querySelector('.Header') || document.querySelector('.HeaderMktg')
    if (!headerDiv) {
      return
    }
    headerDiv.hidden = true
    return () => {
      headerDiv.hidden = false
    }
  }, [])
}

try{ Home.displayName ||= 'Home' } catch {}
try{ Footer.displayName ||= 'Footer' } catch {}