import {Box, Text, type BoxProps} from '@primer/react'
import React, {Fragment, type PropsWithChildren, isValidElement} from 'react'

export default function Footer({children, sx}: PropsWithChildren<BoxProps>) {
  return (
    <Box as="ul" sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', mt: 1, ...sx}}>
      {React.Children.toArray(children)
        .filter(child => !!child) // Filter out falsy children to avoid putting spacers around `null` or `false`
        .map((child, i) => {
          const key = `footer-item-${isValidElement(child) ? child.key : i}`
          const spacer = i === 0 ? null : <FooterSpacer />
          return (
            <Fragment key={key}>
              {spacer}
              {child}
            </Fragment>
          )
        })}
    </Box>
  )
}

function FooterSpacer() {
  return (
    <Text aria-hidden sx={{mx: 2, color: 'fg.subtle'}}>
      ·
    </Text>
  )
}

export function FooterItem({children}: PropsWithChildren<unknown>) {
  return (
    <Box as="li" sx={{listStyle: 'none', display: 'flex', alignItems: 'center', fontSize: 0, color: 'fg.muted'}}>
      {children}
    </Box>
  )
}

try{ Footer.displayName ||= 'Footer' } catch {}
try{ FooterSpacer.displayName ||= 'FooterSpacer' } catch {}
try{ FooterItem.displayName ||= 'FooterItem' } catch {}