import {Box, Text, Heading, Link, Octicon} from '@primer/react'
import {ArrowRightIcon} from '@primer/octicons-react'

export default function MarketingSuggestion({
  title,
  description,
  cta,
  href,
  mediaPath,
  mediaPathRetina,
  mediaAlt,
}: {
  title: string
  description: string
  cta: string
  href: string
  mediaPath: string
  mediaPathRetina?: string
  mediaAlt: string
}) {
  let srcSet = `${mediaPath} 1x`
  if (mediaPathRetina) {
    srcSet += `, ${mediaPathRetina} 2x`
  }
  return (
    <>
      <Box
        sx={{
          bg: 'canvas.default',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'border.default',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'column',
          p: 3,
        }}
      >
        <div>
          <img src={mediaPath} alt={mediaAlt} srcSet={srcSet} />
        </div>
        <Heading as="h2" sx={{fontSize: 2, py: 1, fontWeight: 'semibold'}}>
          {title}
        </Heading>
        <Text sx={{fontSize: 0, color: 'fg.muted', pb: 3}}>{description}</Text>
        <Link sx={{fontSize: 0, fontWeight: 'semibold', pb: 2, display: 'flex', alignItems: 'center'}} href={href}>
          {cta} <Octicon icon={ArrowRightIcon} size={16} sx={{color: 'accent.fg', ml: 1}} />
        </Link>
      </Box>
    </>
  )
}

try{ MarketingSuggestion.displayName ||= 'MarketingSuggestion' } catch {}