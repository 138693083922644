import {ChevronDownIcon, ChevronRightIcon, FoldIcon, GitBranchIcon, InfoIcon, UnfoldIcon} from '@primer/octicons-react'
import {Box, Button, Flash, Link, Octicon, Text} from '@primer/react'
import type {PropsWithChildren} from 'react'
import type React from 'react'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate} from '@github-ui/use-navigate'
import {encodePart} from '@github-ui/paths'
import {SafeHTMLText} from '@github-ui/safe-html'
import {useSearchAnalytics} from '../../hooks/use-search-analytics'
import {timeSinceLastSelection, useTrackSelection} from '../../hooks/use-track-selection'
import type {BlackbirdResults, CodeResult as CodeResultType, SearchResponse, Snippet} from '../../types/blackbird-types'
import EllipsisOverflow from '../EllipsisOverflow'
import LanguageCircle from '../LanguageCircle'
import Result from '../search-result'
import MonaSearchImage from '../NoResults/MonaSearchImage'
import {GitHubAvatar} from '@github-ui/github-avatar'
import {debugScoringInfoEnabled} from '../../../../../components/search/experiments'

// If there are more than this many results, don't bother indicating that duplicates exist
const DUPLICATE_LOCATION_RESULT_LIMIT = 20

// If there are fewer than this many results, automatically expand duplicates
const DUPLICATE_LOCATION_AUTO_EXPAND_LIMIT = 5

// The number of snippets to render by default
const NUM_SNIPPETS_COLLAPSED = 8

export default function Code({
  results,
  isRepoSearch,
}: {
  results: BlackbirdResults & SearchResponse
  isRepoSearch: boolean
}) {
  // Track the user's last interaction with text selection, so that we can
  // accommodate copying parts of result snippets while also allowing navigation
  // when users directly click on a result.
  useTrackSelection()

  const [duplicatesExpanded, setDuplicatesExpanded] = useState(false)
  const [searchResults, duplicateCount, isAcrossRepositories] = useMemo(() => {
    let duplicates = 0
    let acrossRepos = false
    const expandedResults: CodeResultType[] = []
    // Don't bother trying to expand duplicates if we have tons of results
    if (results.result_count <= DUPLICATE_LOCATION_RESULT_LIMIT) {
      duplicates = results.results?.reduce((acc, r) => acc + r.duplicate_locations.length, 0)
      const nwo = results.results?.[0]?.repo_nwo || ''
      acrossRepos = results.results?.some(
        r => r.repo_nwo !== nwo || r.duplicate_locations.some(l => l.repo_nwo !== nwo),
      )
    }

    if (!duplicatesExpanded || duplicates === 0) {
      return [results.results || [], duplicates, acrossRepos]
    }

    // Expand duplicate locations into separate results
    if (results.results) {
      for (const result of results.results) {
        expandedResults.push(result)
        for (const loc of result.duplicate_locations) {
          expandedResults.push(Object.assign({}, result, loc))
        }
      }
    }
    return [expandedResults, duplicates, acrossRepos]
  }, [results, duplicatesExpanded])

  useEffect(() => {
    // Automatically expand duplicates if there are fewer than 10 results
    setDuplicatesExpanded(results.result_count <= DUPLICATE_LOCATION_AUTO_EXPAND_LIMIT)
  }, [setDuplicatesExpanded, results])

  const {sendSampledSearchResult} = useSearchAnalytics()
  useEffect(() => {
    if (results.results) {
      // Randomly select one of the results to sample impressions of
      const position = Math.floor(Math.random() * results.results.length)
      const sampledResult = results.results[position]
      if (sampledResult) {
        sendSampledSearchResult(results.query_id, position, sampledResult)
      }
    }
  }, [results, sendSampledSearchResult])

  // workaround for tsc complaining about the href prop being set when as: 'button' is set
  // as this does not match the semantics of a button
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const linkProps = {href: '#'} as any

  return results.logged_in ? (
    <Result.List sx={{gap: [3, 3, 4, 4]}}>
      {searchResults?.map((item, i) => (
        <CodeResult key={i} item={item} position={i} queryId={results.query_id} shouldRenderRepo={!isRepoSearch} />
      ))}
      {!duplicatesExpanded && duplicateCount > 0 && (
        <Box
          sx={{
            borderColor: 'border.default',
            borderWidth: 1,
            px: '12px',
            py: '12px',
            fontSize: 0,
            width: '100%',
            borderRadius: 2,
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mx: 'auto',
          }}
        >
          <Text sx={{color: 'fg.muted'}}>
            <Octicon icon={InfoIcon} size={16} sx={{mr: 1}} /> We&apos;ve excluded {duplicateCount} identical files
            {isAcrossRepositories ? ' found across repositories' : ''}.{' '}
            <Link inline as="button" onClick={() => setDuplicatesExpanded(true)} {...linkProps}>
              Show identical files
            </Link>
          </Text>
        </Box>
      )}
    </Result.List>
  ) : (
    <Box
      sx={{display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center'}}
    >
      <Box sx={{maxWidth: '400px'}}>
        <MonaSearchImage />
      </Box>
      <Text sx={{fontWeight: 'bold', fontSize: 4, mt: 3}}>Sign in to search code on GitHub</Text>
      <Text sx={{color: 'fg.muted', maxWidth: '400px', textAlign: 'center', mt: 2}}>
        Before you can access our code search functionality please sign in or create a free account.
      </Text>
      <Box sx={{display: 'flex', mt: 3}}>
        <Button as="a" href={results.sign_in_path}>
          Sign in
        </Button>
        {results.sign_up_path && (
          <Button as="a" href={results.sign_up_path} sx={{ml: 2}} variant="primary">
            Sign up
          </Button>
        )}
      </Box>
    </Box>
  )
}

function shouldUsePlainRepresentation(languageId: number) {
  // NOTE: Specially renderable filetypes come from here:
  // https://github.com/github/viewscreen#renderables-supported-so-far
  const specialRenderedLanguageIds = [
    222, // Markdown
    337, // SVG
  ]
  return specialRenderedLanguageIds.includes(languageId)
}

function CodeResult({
  item,
  queryId,
  position,
  shouldRenderRepo,
}: {
  item: CodeResultType
  queryId: string
  position: number
  shouldRenderRepo: boolean
}) {
  const [isOpen, setIsOpen] = useState(true)

  const filePath = item.path
  const owner = item.repo_nwo.split('/')[0]!
  const repoName = item.repo_nwo.split('/')[1]!
  const repositoryOwnerAvatarUrl = `/${owner}.png`
  const repositoryUrl = `/${item.repo_nwo}`
  const plainSuffix = shouldUsePlainRepresentation(item.language_id) ? '?plain=1' : ''
  const htmlUrl = `${repositoryUrl}/blob/${item.commit_sha}/${encodePart(filePath)}${plainSuffix}`
  // remove "ref/heads/" from branch name
  const branchSplit = item.ref_name.split('/').slice(2)
  const branchName = branchSplit.join('/')

  const hasGoodAndBadSnippets = item.snippets.some(i => i.score >= 0) && item.snippets.some(i => i.score < 0)
  const hasMoreSnippets = item.snippets.length > NUM_SNIPPETS_COLLAPSED || hasGoodAndBadSnippets
  const [collapsed, setCollapsed] = useState(hasMoreSnippets)

  const visibleSnippets = collapsed
    ? item.snippets.filter(s => (hasGoodAndBadSnippets ? s.score >= 0 : true)).slice(0, NUM_SNIPPETS_COLLAPSED)
    : item.snippets
  const visibleMatchCount = visibleSnippets.reduce((acc, s) => acc + s.match_count, 0)
  const totalMatchCount = item.match_count
  const reachedLimit = hasMoreSnippets && totalMatchCount - visibleMatchCount > 0 && !collapsed
  const {sendSearchResultClick} = useSearchAnalytics()
  const clickAnalyticsCallback = useCallback(() => {
    sendSearchResultClick(queryId, position, item)
  }, [sendSearchResultClick, queryId, position, item])

  // The default URL should link to the line number of the first visible snippet, which
  // can change if the snippets are expanded.
  let lineNumberSuffix = item.line_number > 1 ? `#L${item.line_number}` : ''
  if (visibleSnippets.length > 0) {
    const firstSnippet = visibleSnippets[0]!
    const lineNumber = Math.floor((firstSnippet.ending_line_number + firstSnippet.starting_line_number) / 2)
    lineNumberSuffix = lineNumber > 1 ? `#L${lineNumber}` : ''
  }
  const defaultUrl = `${htmlUrl}${lineNumberSuffix}`

  const showDebugScoringInfo = debugScoringInfoEnabled()

  return (
    <Box sx={{minWidth: 0}}>
      <Box
        sx={{
          bg: 'canvas.subtle',
          pl: 2,
          pr: 3,
          height: 40,
          listStyle: 'none',
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          borderBottomRightRadius: isOpen ? 0 : 2,
          borderBottomLeftRadius: isOpen ? 0 : 2,
          borderColor: 'border.default',
          borderWidth: 1,
          borderStyle: 'solid',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <OpenCloseButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} name={`${item.repo_nwo} · ${filePath}`} />
        <Box sx={{flex: 1, alignItems: 'center', display: 'flex', ml: 1, minWidth: 0}}>
          <Result.Avatar mr={2}>
            <GitHubAvatar square sx={{ml: '1px'}} size={16} src={repositoryOwnerAvatarUrl} />
          </Result.Avatar>
          <Result.Title fontSize={1}>
            <EllipsisOverflow side="left" title={`${item.repo_nwo} · ${filePath}`}>
              {shouldRenderRepo && (
                <>
                  <Result.RepositoryLink
                    owner={owner}
                    name={repoName}
                    sx={{color: 'fg.default', fontWeight: 'bold', direction: 'ltr', unicodeBidi: 'bidi-override'}}
                  />
                  &nbsp;·&nbsp;
                </>
              )}
              <Link
                href={defaultUrl}
                sx={{color: 'fg.default', fontWeight: 'normal', direction: 'ltr', unicodeBidi: 'bidi-override'}}
                data-testid="link-to-search-result"
              >
                {filePath}
              </Link>
            </EllipsisOverflow>

            {showDebugScoringInfo && (
              <Box sx={{pl: 2}}>{`score=${item.debug_info?.score.toFixed(2)} rpos=${item.debug_info
                ?.retrieval_position}`}</Box>
            )}
          </Result.Title>
        </Box>
        <Box sx={{flexShrink: 0, pl: 4, display: ['none', 'none', 'flex']}}>
          <Result.Footer sx={{mt: 0}}>
            <Result.FooterItem>
              <IconWithText ariaLabel={`${item.language_name} language`} text={item.language_name}>
                <Box sx={{mr: 2}}>
                  <LanguageCircle color={item.language_color} />
                </Box>
              </IconWithText>
            </Result.FooterItem>
            {item.ref_name && (
              <Result.FooterItem>
                <IconWithText
                  ariaLabel={`${branchName} branch`}
                  text={branchName}
                  textUrl={`${repositoryUrl}/tree/${branchName}`}
                >
                  <Octicon
                    icon={GitBranchIcon}
                    size={16}
                    sx={{
                      mr: 1,
                    }}
                  />
                </IconWithText>
              </Result.FooterItem>
            )}
          </Result.Footer>
        </Box>
      </Box>

      {isOpen && (
        <>
          <Box
            sx={{
              borderColor: 'border.default',
              borderBottomWidth: hasMoreSnippets ? 0 : 1,
              borderBottomLeftRadius: hasMoreSnippets ? 0 : 2,
              borderBottomRightRadius: hasMoreSnippets ? 0 : 2,
              borderTopWidth: 0,
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderStyle: 'solid',
            }}
          >
            <MatchSnippet
              defaultUrl={defaultUrl}
              url={htmlUrl}
              snippets={visibleSnippets}
              clickAnalyticsCallback={clickAnalyticsCallback}
            />
          </Box>

          {reachedLimit && (
            <Flash variant="warning" sx={{fontSize: 0, borderRadius: 0, py: 2, px: 3}}>
              This file contains {totalMatchCount - visibleMatchCount} more{' '}
              {totalMatchCount - visibleMatchCount === 1 ? 'match' : 'matches'} not shown.{' '}
              {totalMatchCount === 1 ? (
                <Link href={defaultUrl}>See all matches in the full file</Link>
              ) : (
                <Link href={defaultUrl}>See all {totalMatchCount} matches in the full file</Link>
              )}
            </Flash>
          )}
          {hasMoreSnippets && (
            <Link
              onClick={e => {
                e.preventDefault()
                setCollapsed(!collapsed)
              }}
              href="#"
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: 2,
                bg: 'canvas.default',
                px: 3,
                height: 40,
                color: 'fg.muted',
                borderColor: 'border.default',
                borderWidth: 1,
                borderTopWidth: reachedLimit ? 0 : 1,
                borderStyle: 'solid',
                borderBottomRightRadius: 2,
                borderBottomLeftRadius: 2,
                fontSize: 0,
                ':hover': {
                  bg: 'canvas.subtle',
                },
              }}
            >
              <Octicon
                icon={collapsed ? UnfoldIcon : FoldIcon}
                size={16}
                sx={{
                  mr: 2,
                }}
              />
              {collapsed
                ? `Show ${totalMatchCount - visibleMatchCount} more match${
                    totalMatchCount - visibleMatchCount === 1 ? '' : 'es'
                  }`
                : `Show less`}
            </Link>
          )}
        </>
      )}
    </Box>
  )
}

function OpenCloseButton({isOpen, onClick, name}: {isOpen: boolean; onClick: () => void; name: string}) {
  return (
    <Box
      as="button"
      onClick={onClick}
      aria-label={isOpen ? `Collapse ${name}` : `Expand ${name}`}
      sx={{
        height: 24,
        borderRadius: 2,
        width: 24,
        color: 'fg.muted',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bg: 'canvas.subtle',
        border: 'none',
        ':hover': {
          bg: 'btn.hoverBg',
        },
      }}
    >
      {isOpen ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />}
    </Box>
  )
}

function MatchSnippet({
  url,
  defaultUrl,
  snippets,
  clickAnalyticsCallback,
}: {
  url: string | undefined
  snippets: Snippet[]
  defaultUrl: string
  clickAnalyticsCallback: () => void
}) {
  const nav = useNavigate()

  const snippetElements = []
  let nextLineNumber: number | undefined = undefined
  let lineNumberAlternation = false
  for (const snippet of snippets) {
    if (nextLineNumber !== undefined && nextLineNumber !== snippet.starting_line_number) {
      lineNumberAlternation = !lineNumberAlternation
      snippetElements.push(
        <tr key={`divider-${snippetElements.length}`} role="row">
          <td colSpan={2} role="cell">
            <div style={{marginTop: 4, marginBottom: 7}} />
          </td>
        </tr>,
      )
    }
    nextLineNumber = snippet.starting_line_number + snippet.lines.length

    snippetElements.push(
      snippet.lines.map((line, i) => {
        const lineNumber = i + snippet.starting_line_number
        const fileLink = `${url}#L${lineNumber}`

        const navigateToSnippet = (e: React.MouseEvent) => {
          if (!window.getSelection()?.isCollapsed) {
            e.stopPropagation()
            return
          }

          // Allow users to select and manipulate their selection without
          // triggering navigation
          if (timeSinceLastSelection() < 100) {
            e.stopPropagation()
            return
          }

          clickAnalyticsCallback()

          // NOTE: I have to disable these checks bc this has nothing to do with hotkeys
          // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
          if (e.ctrlKey || e.metaKey) {
            window.open(fileLink, '_blank')
          } else {
            nav(fileLink)
          }

          e.stopPropagation()
        }

        return (
          <tr key={fileLink} onClick={navigateToSnippet} role="row">
            <td className="blob-num" role="cell">
              <Link onClick={e => e.stopPropagation()} href={fileLink}>
                <Text sx={{color: lineNumberAlternation ? 'fg.default' : 'ft.muted'}}>{lineNumber}</Text>
              </Link>
            </td>
            <td className="blob-code blob-code-inner" role="cell">
              {snippet.format === 'SNIPPET_FORMAT_HTML' ? (
                <SafeHTMLText
                  as="span"
                  html={snippet.lines[i]!}
                  sx={{
                    cursor: 'text',
                    wordWrap: 'break-word',
                    color: 'fg.default',
                    overflowWrap: 'anywhere',
                    mark: {
                      backgroundColor: 'var(--highlight-neutral-bgColor, var(--color-search-keyword-hl))',
                      color: 'var(--fgColor-default, var(--color-fg-default))',
                    },
                  }}
                />
              ) : (
                <Text
                  as="span"
                  sx={{
                    cursor: 'text',
                    wordWrap: 'break-word',
                    color: 'fg.default',
                    overflowWrap: 'anywhere',
                    mark: {
                      backgroundColor: 'var(--highlight-neutral-bgColor, var(--color-search-keyword-hl))',
                      color: 'var(--fgColor-default, var(--color-fg-default))',
                    },
                  }}
                >
                  {line}
                </Text>
              )}
            </td>
          </tr>
        )
      }),
    )
  }

  return (
    <Box
      className="code-list"
      sx={{
        whiteSpace: 'pre-wrap',
        fontFamily: 'monospace',
        minWidth: 0,
        cursor: 'pointer',
        py: 2,
      }}
      onClick={(e: React.MouseEvent) => {
        // Allow users to select and manipulate their selection without
        // triggering navigation
        if (timeSinceLastSelection() < 100) {
          return
        }
        // Only trigger a navigation if the user isn't selecting something
        if (window.getSelection()?.isCollapsed) {
          clickAnalyticsCallback()

          // Open in a new tab if ctrl or cmd key are pressed
          // NOTE: I have to disable these checks bc this has nothing to do with hotkeys
          // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
          if (e.ctrlKey || e.metaKey) {
            window.open(defaultUrl, '_blank')
          } else {
            nav(defaultUrl)
          }
        }
      }}
    >
      <table role="table">
        {
          // We need to add role="rowgroup" because we don't have table headers
          // And in order to screen readers to know that we really mean for this to be a table
          // We must define it explicitly
          <tbody role="rowgroup">{snippetElements}</tbody>
        }
      </table>
    </Box>
  )
}

interface IconWithTextProps extends PropsWithChildren<unknown> {
  ariaLabel: string
  text: string
  textUrl?: string
}

function IconWithText({ariaLabel, text, textUrl, children}: IconWithTextProps) {
  const inner = (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      {children}
      {/* eslint-disable-next-line github/a11y-role-supports-aria-props */}
      <span aria-label={ariaLabel}>{text}</span>
    </Box>
  )

  if (!textUrl) return inner

  return (
    <Link href={textUrl} sx={{color: 'fg.muted'}}>
      {inner}
    </Link>
  )
}

try{ Code.displayName ||= 'Code' } catch {}
try{ CodeResult.displayName ||= 'CodeResult' } catch {}
try{ OpenCloseButton.displayName ||= 'OpenCloseButton' } catch {}
try{ MatchSnippet.displayName ||= 'MatchSnippet' } catch {}
try{ IconWithText.displayName ||= 'IconWithText' } catch {}