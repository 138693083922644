import type {PropsWithChildren} from 'react'
import {Box} from '@primer/react'

export default function Result({children}: PropsWithChildren) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          borderRadius: 2,
          borderColor: 'border.default',
          borderWidth: 1,
          borderStyle: 'solid',
          p: 3,
          px: 3,
          width: '100%',
          maxWidth: '100%',
        }}
      >
        {children}
      </Box>
    </>
  )
}

try{ Result.displayName ||= 'Result' } catch {}